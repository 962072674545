export const members = [
  {
    name: "Ronan Clooney",
    role: "Tech Lead",
    info: "Ronan is passionate about the real-world use cases of blockchain technology and how individuals can leverage it to enhance their banking/investing experiences. He uses his full-stack capabilities to ensure high-quality and secure software solutions are delivered.",
    linkedIn: "https://www.linkedin.com/in/ronan-clooney/",
    twitter: "https://twitter.com/ronannclooney",
    img: "team/ronan.jpeg",
  },
  {
    name: "Giles Campbell",
    info: "Giles has a 15 year background in genetics and science communication and support, having established himself within the scientific field he became interested in blockchain technology and its potential for data science. He has been focused on Algorand since he learnt of the project in 2018, he is passionate about support not only for Algomint but for the Algorand community as a whole.",
    linkedIn: "https://www.linkedin.com/in/giles-campbell-7ba51693/",
    twitter: "https://twitter.com/AlgomintTnt",
    img: "team/giles.jpeg",
    role: "Lead",
  },
  {
    name: "Kumar Gaurav",
    info: "Gaurav is enthusiastic Web3 Frontend Engineer with 4 years of experience, specialized in crafting user-friendly interfaces for decentralized applications (dApps) and have a strong track record of successful contributions to blockchain projects across various platforms, including TRON, Ethereum, and Algorand.",
    linkedIn: "https://www.linkedin.com/in/arkhaminferno/",
    twitter: "https://twitter.com/Infern0ftw",
    img: "team/gaurav.jpg",
    role: "Frontend Engineer",
  },
];

export const founders = [
  {
    name: "Michael Cotton",
    role: "Founder",
    info: "Michael has been an entrepreneur in the precious metals industry since 18. Having successfully founded the Imperial Group, Meld Gold, Meld Ventures, and Algomint, he is well known as someone who identifies new opportunities for growth and innovation.",
    linkedIn: "https://www.linkedin.com/in/michael-cotton-aus/",
    twitter: "https://twitter.com/MichaelDCotton",
    img: "team/michael.jpg",
  },
  {
    name: "AJ Milne",
    role: "Founder",
    info: "AJ has a background in entrepreneurialism, business advisory, and investment. AJ has served in leadership roles at a global fintech, a corporate vc fund, and a P2P marketplace, he is the CEO of Optio Capital and Founder of Meld Ventures & Algomint. Having also completed a MIT Executive Blockchain course, he provides practical and theoretical expertise in the blockchain sector.",
    linkedIn: "https://www.linkedin.com/in/astleymilne/",
    twitter: "https://twitter.com/AJ_Milne",
    img: "team/aj.jpg",
  },
];

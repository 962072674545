export const investorItems = [
  {
    image: "borderless",
  },
  {
    image: "gsr",
  },
  {
    image: "ld",
  },
  {
    image: "cluster",
  },
  {
    image: "ngc",
  },
  {
    image: "ellipti",
  },
  {
    image: "kosmos",
  },
  {
    image: "ybb",
  },
  {
    image: "bitfwd",
  },
  {
    image: "meldventures",
  },
  {
    image: "chi",
  },
  {
    image: "valhalla",
  },
  {
    image: "goldentree",
  },
  {
    image: "brevanhoward",
  },
  {
    image: "shimacapital",
  },
  {
    image: "genesiscapital",
  },
  {
    image: "mexc",
  },
  {
    image: "vantagepoint",
  },
  {
    image: "silverfox",
  },
  {
    image: "bigbrain",
  },
  {
    image: "EXAfinance",
  },
  {
    image: "astrapto",
  },
  {
    image: "crtlabs",
  },
  {
    image: "kronos",
  },
  {
    image: "folksfinance",
  },
  {
    image: "0xventures",
  },
  {
    image: "pareacapital",
  },
];

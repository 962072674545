import React from "react";
import algomintClip from "../../../static/algomint-clip.mp4";
import "../VideoBanner/VideoBanner.css";
import Fade from "../../animations/Fade";

export default function VideoBanner() {
  return (
    <div>
      <Fade direction={"bottomToTop"} duration={1}>
        <video className="videoItem" loop autoPlay muted playsInline>
          <source src={algomintClip} type="video/mp4" />
        </video>
      </Fade>
    </div>
  );
}

import React from "react";
import "./Card.css";
import HyperLinkButton from "../HyperlinkButton";
import Fade from "../../animations/Fade";
export default function card({
  title,
  role,
  description,
  linkedIn,
  twitter,
  img,
  styles,
}) {
  return (
    <div className={`max-w-xs mx-6  shadow-lg my-4 ${styles ? styles : ""}`}>
      <Fade direction={"bottomToTop"} duration={1}>
        <img className="team-member-image" src={img} alt="team-member-name" />
        <div className="px-6 py-4">
          <div className="title mb-2">{title}</div>
          <div className="role mb-2 uppercase">{role}</div>
          <p className="text-base mb-2 card-description">{description}</p>
          <br />
          <br />
          <div className="flex flex-col space-y-6">
            {linkedIn ? (
              <HyperLinkButton title={"LINKEDIN"} url={linkedIn} />
            ) : (
              ""
            )}
            {twitter ? <HyperLinkButton title={"TWITTER"} url={twitter} /> : ""}
          </div>
        </div>
      </Fade>
    </div>
  );
}

import React from "react";
import { investorItems } from "../data/InvestorItems";
import "./investor.css";
import Fade from "../../animations/Fade";

export default function Investors() {
  return (
    <div className=" wrapper p-12 mt-8">
      <div className="flex justify-between pb-6">
        <h1 className="text-4xl font-medium ">
          <Fade direction={"bottomToTop"} duration={1}>
            INVESTORS
          </Fade>
        </h1>
        <p className="date sm:text-lg">2020-22</p>
      </div>
      <hr className="bg-gray-500" />
      <div className="investor grid grid-cols-6">
        {investorItems.map((investor, index) => {
          return (
            <Fade direction={"bottomToTop"} duration={1} key={index}>
              <img
                className="grid  mt-8 max-h-40 pt-6"
                src={`./investors/${investor.image}.png`}
                alt="investors"
              />
            </Fade>
          );
        })}
      </div>
    </div>
  );
}

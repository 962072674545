import React from "react";
import "./partner.css";
import { partnerItems } from "../data/PartnerItem";
import Fade from "../../animations/Fade";

export default function Partners() {
  return (
    <div className="wrapper">
      <div className="flex justify-between pb-6">
        <h1 className="text-4xl font-medium">
          <Fade direction={"bottomToTop"} duration={1}>
            PARTNERS
          </Fade>
        </h1>
        <p className="date sm:text-lg">2020-22</p>
      </div>
      <hr />
      <div className="partner grid grid-cols-6 py-6">
        {partnerItems.map((partner, index) => {
          return (
            <Fade direction={"bottomToTop"} duration={1} key={index}>
              <img
                className="partner-image grid mt-8"
                src={`./partners/${partner.image}.png`}
                alt="partner"
              />
            </Fade>
          );
        })}
      </div>
    </div>
  );
}

/**
 * POST feedback data to Zendesk
 * https://developer.zendesk.com/rest_api/docs/core/requests#create-request
 *
 * @param {Object} data      - Feedback data payload *
 * @return {Object}          - Response from Zendesk
 *
 */
export const sendFeedback = async (data) => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    const response = await fetch(
      `https://algomintbridge.zendesk.com/api/v2/requests.json`,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers,
      }
    );
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    return error;
  }
};

/**
 * Format a payload of form data to the format required by Zendesk
 *
 * @param {String} email  - Email provided by user giving feedback
 * @param {String} body - Message provided by user
 * @param {String} name  - Name provided by user
 *
 * @return {Object}         - Payload for Zendesk Requests API
 *
 */
export const formatPayload = (email, name, title, body, token) => {
  const requester = { name, email };
  console.log(token, "t");
  const comment = token ? { body, uploads: [token] } : { body };
  return {
    request: {
      requester,
      subject: title.length ? title : "algomint",
      comment,
    },
  };
};

export const uploadFile = async (filename, file) => {
  try {
    const headers = { "Content-Type": "application/binary" };
    const response = await fetch(
      `https://algomintbridge.zendesk.com/api/v2/uploads.json?filename=${filename}`,
      {
        method: "POST",
        body: file,
        headers,
      }
    );
    const responseData = await response.json();
    return responseData.upload.token;
  } catch (error) {
    return error;
  }
};

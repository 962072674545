import React, { useState } from "react";
import "./TvlassetsV2.css";
export default function TvlAssetsV2(props) {
  const [selected, setSelected] = useState(false);
  const toggle = () => {
    setSelected((v) => !v);
  };
  return (
    <>
      <div className="tvl-wrapper flex xs:flex-col xs:items-center xs:w-full sm:flex-row sm:items-center md:flex-row lg:flex-row xl:flex-row 2xl:flex-row w-full h-36">
        <div
          className={`tvl-title-container tvl-title-${props.titleBgColor}-refactor xs:w-full sm:w-full md:w-4/12 lg:w-4/12 xl:w-4/12 2xl:w-4/12 h-full flex items-center justify-center`}
        >
          <div className="flex flex-col space-y-4 xs:space-y-0 sm:space-y-0">
            <h1 className="uppercase break-words font-bold xs:text-xs xs:text-center sm:text-sm md:text-xs lg:text-lg xl:text-xl 2xl:text-2xl sm:text-center">
              {" "}
              {props.firstTitle}
            </h1>
            <hr className="tvl-title-underline" />
            <h1 className="uppercase break-words font-bold xs:text-xs xs:text-center sm:text-sm md:text-xs lg:text-lg xl:text-xl 2xl:text-2xl sm:text-center">
              {" "}
              {props.secondTitle}
            </h1>
          </div>
        </div>
        <div className="tvl-content-container xs:w-full sm:w-full md:w-8/12 lg:w-8/12 xl:w-8/12 2xl:w-8/12 flex flex-row">
          <div
            className={`flex xs:flex-col ${
              props.displayChevron ? "xs:ml-8" : ""
            }  sm:flex-col md:flex-col lg:flex-col xl:flex-col 2xl:flex-col justify-center text-center w-full`}
          >
            <div className="w-full h-full circulating-assets-details flex xs:flex-col sm:flex-col xs:p-2 sm:p-2">
              {Object.keys(props.summary).map((assetName, index) => {
                return (
                  <div
                    className={`tvl-asset-details flex xs:p-0 sm:p-1 ${
                      props.displayChevron ? "space-x-4" : ""
                    } items-center justify-center w-full h-full`}
                    key={index}
                  >
                    <div className="summary-assetName flex xs:text-xs sm:text-sm md:text-xs lg:text-lg xl:text-xl 2xl:text-2xl">
                      {" "}
                      <img
                        src={`${assetName}.svg`}
                        className="tvl-asset-img"
                        alt="crypto-logo"
                      />
                      {assetName}
                    </div>

                    <div className="flex flex-col space-y-3 xs:space-y-1 sm:space-y-1">
                      <div className="tvl-asset tvl-circulating-asset xs:text-xs sm:text-sm md:text-xs lg:text-lg xl:text-xl 2xl:text-2xl w-1/2">
                        {parseFloat(
                          props.summary[assetName].circulating
                        ).toFixed(2)}{" "}
                      </div>
                      <hr className="summary-horizontal-divider" />
                      <div className="tvl-asset tvl-collateral-asset xs:text-xs sm:text-sm md:text-xs lg:text-lg xl:text-xl 2xl:text-2xl w-1/2">
                        {parseFloat(
                          props.summary[assetName].collateral
                        ).toFixed(2)}{" "}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <br />
      <div
        className={`${
          props.displayChevron
            ? "tvl-chevron-wrapper mt-5 flex flex-row justify-center"
            : "hidden"
        }`}
      >
        {props.displayChevron ? (
          <div
            className="tvl-chevron"
            onClick={() => {
              toggle();
            }}
          >
            <span>
              <img
                src={"/icons/chevron.png"}
                className={`h-12 w-12 ease-in duration-300 cursor-pointer ${
                  selected ? "rotate-180" : "-rotate-0"
                }`}
                alt="chevron"
              />
            </span>
          </div>
        ) : (
          ""
        )}
      </div>
      <div
        className={
          selected ? "tvl-expanded-content show" : "tvl-expanded-content"
        }
      >
        <br />
        <br />
        {props.expandedContent}{" "}
      </div>
      <br />
      <br />
    </>
  );
}

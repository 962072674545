import React, { useState } from "react";
import VideoPlayer from "../VideoPlayer";
import Fade from "../../animations/Fade";
import "./AlgomintVideo.css";

export default function algomintVideo() {
  const [playVideo, setPlayVideo] = useState(false);
  return (
    <Fade direction={"bottomToTop"} duration={1}>
      <div className="videoplayer-wrapper  cursor-pointer bg-black w-full h-96 flex items-center justify-center my-20 xs:text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl">
        {playVideo ? (
          <VideoPlayer
            isVideoPlaying={playVideo}
            stopVideo={() => setPlayVideo(false)}
          />
        ) : (
          <div className="flex flex-col space-y-4">
            <div className="flex flex-row justify-center">
              <img
                src={"play-btn.svg"}
                className="cursor-pointer play-btn"
                alt="video-play-button"
                onClick={() => {
                  setPlayVideo(true);
                }}
              />
            </div>
            <span>Click to learn more about Algomint</span>
          </div>
        )}
      </div>
    </Fade>
  );
}

import React from "react";
import "./LearnMore.css";
import HyperLinkButton from "../HyperlinkButton";
import Fade from "../../animations/Fade";
export default function learMore() {
  return (
    <div className="space-y-44">
      <div className="learnMore-wrapper w-full h-full flex flex-col space-y-6">
        <Fade direction={"bottomToTop"} duration={1}>
          <div className="learnMore-header xs:text-3xl sm:text-4xl md:text-4xl lg:text-4xl xl:text-4xl 2xl:text-4xl tracking-tighter font-medium uppercase">
            LEARN MORE ABOUT ALGOMINT
          </div>
        </Fade>
        <div className="w-full h-full flex xs:flex-col xs:space-y-4 sm:flex-col sm:space-y-4 md:space-y-4 md:flex-col lg:flex-row xl:flex-row 2xl:flex-row">
          <Fade direction={"bottomToTop"} duration={1}>
            <p className="learnMore-paragraph text-base sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl font-light leading-6  xs:w-full sm:w-full md:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2">
              Algomint is the next generation blockchain infrastructure that
              solves the interoperability challenge. We bring popular assets to
              Algorand where you can enjoy high speed and low fees in its
              flourishing DeFi scene.
            </p>
          </Fade>
          <Fade direction={"bottomToTop"} duration={1}>
            <p className="text-base font-light leading-6  xs:w-full sm:w-full md:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 learnMore-paragraph ">
              Holders can join the party too. You can now store your BTC and ETH
              in the secure vault of Copper.co, mint goBTC and goETH on a 1:1
              ratio, and redeem later on demand.
            </p>
          </Fade>
        </div>
      </div>

      <div className="Algorand-future-wrapper w-full h-full flex flex-col space-y-6">
        <div className="w-full h-full flex xs:flex-col xs:space-y-4 sm:flex-col sm:space-y-4 md:space-y-4 md:flex-col lg:flex-row xl:flex-row 2xl:flex-row">
          <div className="flex flex-col space-y-12 xs:w-full sm:w-full md:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2">
            <div className="learnMore-header xs:text-3xl sm:text-4xl md:text-4xl lg:text-4xl xl:text-4xl tracking-tighter font-medium">
              <Fade direction={"bottomToTop"} duration={1}>
                ALGORAND IS THE FUTURE
              </Fade>
            </div>
            <div className="xs:space-y-6 sm:space-y-12 md:space-y-64 lg:space-y-64 xl:space-y-64 2xl:space-y-64 ">
              <Fade direction={"bottomToTop"} duration={1}>
                <p className="text-base font-light leading-6 ">
                  Algorand, founded by cryptography pioneer and Turing award
                  winner Silvio Micali, is a future-proof blockchain that has
                  solved the trilemma of security, scalability, and
                  decentralization. Check out its rich ecosystem to learn more.
                </p>
              </Fade>
              <Fade direction={"bottomToTop"} duration={1}>
                <HyperLinkButton
                  title={"GO TO ALGORAND.COM"}
                  url={"https://www.algorand.com/"}
                />
              </Fade>
            </div>
          </div>
          <div className="flex flex-col  xs:w-full sm:w-full md:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 space-y-14 xs:text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl">
            <Fade direction={"bottomToTop"} duration={1}>
              <div className="flex flex-col">
                <div className="flex flex-row justify-center">
                  <img
                    src={"transactionSpeed.svg"}
                    alt="algorand stats"
                    className="transaction-icons"
                  />
                </div>
                <div className="text-center">Transaction speed</div>
                <div className="text-center">4 Seconds</div>
              </div>
            </Fade>
            <Fade direction={"bottomToTop"} duration={1}>
              <div className="flex flex-col">
                <div className="flex flex-row justify-center">
                  <img
                    src={"/transactionCost.svg"}
                    alt="algorand stats"
                    className="transaction-icons"
                  />
                </div>
                <div className="text-center">Transaction Cost</div>
                <div className="text-center">0.001 $ALGO</div>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
}

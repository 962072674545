import React from "react";
import "./FormSubmissionMessage.css";
export default function formSubmissionMessage({ message }) {
  return (
    <div className="flex formSubmissionMessage-wrapper rounded-lg xs:text-xs xs:p-1.5 sm:text-sm md:text-sm lg:text-sm xl:text-sm text-base tracking-wide font-normal text-center h-20 justify-center items-center">
      {message == "Success"
        ? "Thank you! Your submission has been received!"
        : "There is some error. Please try again."}
    </div>
  );
}

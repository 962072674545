export const partnerItems = [
  {
    image: "copper"
  },
  {
    image: "algorand"
  },
  {
    image: "algofoundation"
  }
]
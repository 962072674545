import React from "react";
import "./ArrowButton.css";

export default function ArrowButton({ title, URL }) {
  return (
    <button className="arrowBtn flex space-x-6 2xl:space-x-4">
      <span className="mt-4 xs:mt-2 mx-1 md:mt-4 md:mx-0 lg:mt-0 2xl:mt-4">
        <svg
          width="32"
          height="32"
          viewBox="0 0 44 44"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="w-6 "
          role="img"
        >
          <circle cx="22" cy="22" r="22" fill="#ffffff" />
          <path
            d="M29.0095 12.0596C28.9114 11.5161 28.3913 11.155 27.8478 11.2531L18.9909 12.8517C18.4474 12.9498 18.0863 13.4699 18.1844 14.0134C18.2825 14.5569 18.8026 14.918 19.3461 14.8199L27.2189 13.3989L28.6399 21.2717C28.7379 21.8152 29.2581 22.1763 29.8016 22.0782C30.3451 21.9801 30.7061 21.46 30.6081 20.9165L29.0095 12.0596ZM15.8215 31.5703L28.8468 12.8075L27.2039 11.6669L14.1785 30.4297L15.8215 31.5703Z"
            fill="black"
          />
        </svg>
      </span>
      <a
        href={URL}
        target="_blank"
        className="xs:text-xs sm:text-sm md:text-md lg:text-md xl:text-md 2xl:text-md  mt-5 xs:mt-4 md:mt-4 2xl:mt-4 lg:mt-1"
      >
        {title}
      </a>
    </button>
  );
}

import React, { useState } from "react";
import { uploadFile } from "../../utils/zendeskAPI";
import "./ImageUploader.css";
import { SpinnerCircular } from "spinners-react";
export default function imageUploader({ setfileUploadToken }) {
  const [fileName, setFileName] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({
    isError: false,
    description: "",
  });

  const beforeUpload = (file) => {
    setIsLoading(true);
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      setError({
        ...error,
        isError: true,
        description: "Image must smaller than 2MB",
      });
      cancelUpload();
      return;
    }
    return file;
  };
  const handleFileInputChange = async (e) => {
    const file = beforeUpload(e.target.files[0]);
    if (file) {
      const token = await uploadFile(file.name, file);
      try {
        setfileUploadToken(token);
        setIsLoading(false);
        setFileName(file.name);
        setError({
          ...error,
          isError: false,
          description: "",
        });
      } catch (error) {
        cancelUpload();
        setError({
          ...error,
          isError: true,
          description:
            "There is some error on uploading image.Please try again",
        });
      }
    }
  };
  const cancelUpload = () => {
    setfileUploadToken(null);
    setFileName(null);
    setIsLoading(false);
  };
  return (
    <div className="flex flex-col xs:my-6 md:my-6">
      <div className="block text-sm font-normal ml-2 mb-2 uppercase">
        ATTACH IMAGE*
      </div>
      <div className="flex space-x-6">
        <div
          className={`flex  items-center justify-center border custom-file-upload h-full ${
            fileName ? "w-full h-full" : "rounded-full w-44"
          }`}
        >
          {fileName ? (
            <div className="flex space-x-6 upload-text">
              {" "}
              <h1>{fileName}</h1>
              <div className="mx-4 cursor-pointer" onClick={cancelUpload}>
                X
              </div>
            </div>
          ) : (
            <label htmlFor="img-upload" className="cursor-pointer">
              <div className="flex justify-center items-center space-x-2 ">
                {isLoading ? (
                  <SpinnerCircular className="h-6 w-6" />
                ) : (
                  <img
                    src={"/icons/upload.svg"}
                    alt="uploadIcon"
                    className="upload-icon"
                  />
                )}
                <span className="upload-text">
                  {isLoading ? "Uploading..." : "Upload File"}
                </span>
                <input
                  id="img-upload"
                  type="file"
                  className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                  accept="image/*"
                  onChange={handleFileInputChange}
                />
              </div>
            </label>
          )}
        </div>
        {!fileName ? (
          <span className="align-bottom xs:text-sm sm:text-sm md:text-md lg:text-md xl:text-md 2xl:text-md">
            Max file size 2MB
          </span>
        ) : (
          ""
        )}
      </div>

      {error.isError ? (
        <p className="upload-error text-sm">{error.description}</p>
      ) : (
        ""
      )}
    </div>
  );
}

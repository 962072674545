import React from "react";
import "../AssetBanner/AssetBanner.css";
import Fade from "../../animations/Fade";
import ButtonTransparent from "../ButtonTransparent";
import TransactionCounter from "../TxnCountDown";

export default function AssetBanner() {
  return (
    <div className="AssetBanner-wrapper  mt-52  2xl:space-y-32 space-y-32 w-full h-full flex flex-col">
      <h1 className="AssetBanner-heading">
        <Fade direction={"bottomToTop"} duration={1}>
          Bringing BTC, ETH and <br /> Stablecoins to Algorand
        </Fade>
      </h1>
      <Fade direction={"bottomToTop"} duration={1}>
        {/* <TransactionCounter endpoint={"https://indexer.algoexplorerapi.io/rl/v1/transactions?page=1&&limit=10&&asset-id=386192725"} /> */}
      </Fade>
      <div className="flex xs:flex-col xs:space-y-4 sm:flex-row sm:space-y-4 sm:space-x-4 sm:space-y-4 md:flex-row lg:flex-row xl:flex-row 2xl:flex-row 2xl:justify-center xl:space-x-8 2xl:space-x-8">
        <div className="flex flex-col space-y-1 sm:py-3">
          <Fade direction={"bottomToTop"} duration={1}>
            <ButtonTransparent
              title={"Tokenomics"}
              url={"documents/Algomint_Tokenomics.pdf"}
              displayIcon={true}
              showBorder={false}
            />
          </Fade>
          <Fade direction={"bottomToTop"} duration={1}>
            <ButtonTransparent
              title={"Litepaper"}
              url={"documents/Algomint_Litepaper.pdf"}
              displayIcon={true}
              showBorder={false}
            />
          </Fade>
        </div>
        <Fade direction={"bottomToTop"} duration={1}>
          <p className="text-base mr-8 xs:py-4 xs:text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl">
            Building interoperability for the next <br /> generation of
            blockchains
          </p>
        </Fade>
        <Fade direction={"bottomToTop"} duration={1}>
          <p className="text-desc text-base xs:text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl">
            High speed and low fees in <br /> Algorand's flourishing DeFi scene{" "}
          </p>
        </Fade>
      </div>
    </div>
  );
}

import React from "react";
import "./TVLGroupedData.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import { getEquivalentAssetName } from "../../utils/utils";

function openBlockExplorer(assetName, address) {
  switch (assetName) {
    case "btc":
      window.open(`https://blockstream.info/address/${address}`);
      break;
    case "eth":
      window.open(`https://etherscan.io/address/${address}`);
      break;
    case "WBTC":
      window.open(`https://etherscan.io/address/${address}`);
      break;
    case "goETH":
      window.open(`https://allo.info/address/${address}`);
      break;
    case "goBTC":
      window.open(`https://allo.info/address/${address}`);
      break;
    case "goUSD":
      window.open(`https://allo.info/address/${address}`);
      break;
    case "USDC":
      window.open(`https://allo.info/address/${address}`);
      break;
    case "algo":
      window.open(`https://allo.info/address/${address}`);
      break;
    default:
      return;
  }
}

function TVLGroupedData({ algomintAssets, baskets }) {
  return (
    <>
      {Object.keys(algomintAssets).map((assetName, index) => {
        return (
          <div className="tvl-grouped-data-container flex flex-col" key={index}>
            <div className="custody-table-assetName-wrapper flex ml-4 space-x-2 mb-2">
              <img
                src={`${assetName}.svg`}
                className="custody-table-assetImg"
                alt=""
                height={"30px"}
                width={"30px"}
              />{" "}
              <div className="custody-table-assetname">
                {assetName} Total Collateral{" "}
                {algomintAssets[`${assetName}`].totalCollateral.toFixed(4)}
              </div>
            </div>
            <div>
              <TableContainer
                style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
                component={Paper}
                key={index}
              >
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ color: "#fff" }}>
                        Wallet Address
                      </TableCell>
                      <TableCell align="right" style={{ color: "#fff" }}>
                        Balance Amount
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {algomintAssets[`${assetName}`].composition.map(
                      ({ chain, type, address, amount, token }, id) => {
                        return (
                          <TableRow key={id}>
                            <TableCell
                              component="th"
                              className="table-cell w-full"
                              scope="row"
                              style={{
                                color: "#FFF",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                openBlockExplorer(chain, address);
                              }}
                            >
                              {address}
                            </TableCell>
                            <TableCell align="right" style={{ color: "#fff" }}>
                              <div className="flex space-x-1 justify-end ">
                                <div>{amount}</div>
                                <div>
                                  {type.includes("algo")
                                    ? token.toUpperCase()
                                    : chain.toUpperCase()}
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <br />
            </div>
          </div>
        );
      })}

      {Object.keys(baskets).map((assetName, index) => {
        return (
          <React.Fragment key={index}>
            <div
              className="custody-table-assetName-wrapper flex ml-4 space-x-2 mb-2"
              key={index}
            >
              <img
                src={`${assetName}.svg`}
                className="custody-table-assetImg"
                alt=""
                height={"30px"}
                width={"30px"}
              />{" "}
              <div className="custody-table-assetname text-white">
                {assetName + " LP Tokens"}
              </div>
            </div>
            <TableContainer
              style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
              component={Paper}
            >
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ color: "#fff" }}>Token Name</TableCell>
                    <TableCell style={{ color: "#fff" }}>
                      Token Address
                    </TableCell>
                    <TableCell style={{ color: "#fff" }}>LP Amount</TableCell>
                    <TableCell align="right" style={{ color: "#fff" }}>
                      Token Amount
                    </TableCell>
                    <TableCell align="right" style={{ color: "#fff" }}>
                      goToken Amount
                    </TableCell>
                  </TableRow>
                </TableHead>
                {baskets[assetName].map(
                  (
                    {
                      tokenName,
                      address,
                      lpAmount,
                      tokenAmount,
                      goTokenAmount,
                      chain,
                    },
                    index
                  ) => {
                    return (
                      <TableBody key={index}>
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell align="left" style={{ color: "#fff" }}>
                            {tokenName.toUpperCase()}
                          </TableCell>
                          <TableCell
                            component="th"
                            className="table-cell"
                            scope="row"
                            style={{
                              color: "#FFF",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              openBlockExplorer(chain, address);
                            }}
                          >
                            {address}
                          </TableCell>
                          <TableCell align="left" style={{ color: "#fff" }}>
                            {lpAmount}
                          </TableCell>
                          <TableCell style={{ color: "#fff" }}>
                            <div className="flex space-x-1 justify-end">
                              <div>{tokenAmount}</div>
                              <div>{tokenName.toUpperCase()}</div>
                            </div>
                          </TableCell>
                          <TableCell align="right" style={{ color: "#fff" }}>
                            <div className="flex space-x-1 justify-end">
                              <div>{goTokenAmount}</div>
                              <div>{assetName.toUpperCase()}</div>
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    );
                  }
                )}
              </Table>
            </TableContainer>
            <br />
          </React.Fragment>
        );
      })}
    </>
  );
}

export default TVLGroupedData;

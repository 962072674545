import React, { useState, useRef } from "react";
import ReCaptchaV2 from "react-google-recaptcha";
import Input from "../Input";
import { sendFeedback, formatPayload } from "../../utils/zendeskAPI";
import FormSubmissionMessage from "../FormSubmissionMessage";
import ImageUploader from "../ImageUploader";
import Fade from "../../animations/Fade";
import "./ContactForm.css";

export default function contactForm() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [fileUploadToken, setfileUploadToken] = useState(null);
  const [reCAPTCHAToken, setreCAPTCHAToken] = useState(null);
  const [isFormSubmitted, setisFormSubmitted] = useState(false);
  const [formSubmissionStatus, setformSubmissionStatus] = useState(null);
  const form = useRef();

  const handleExpire = () => {
    setreCAPTCHAToken(null);
  };
  const handleToken = (token) => {
    setreCAPTCHAToken(token);
  };

  const handleError = () => {
    setreCAPTCHAToken(null);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!reCAPTCHAToken) {
      return;
    }
    console.log({ firstName, lastName, email, message }, "Abk");
    const payload = formatPayload(
      email,
      `${firstName} ${lastName}`,
      "algomint",
      message,
      fileUploadToken
    );
    const response = await sendFeedback(payload);
    if (response !== undefined) {
      if (response.error) {
        setisFormSubmitted(true);
        setformSubmissionStatus("Error");
      } else {
        setisFormSubmitted(true);
        setformSubmissionStatus("Success");
      }
    }
  };

  return (
    <div
      id="support-form-wrapper"
      className="form-wrapper bg-black w-full h-auto flex flex-col my-48"
    >
      {isFormSubmitted ? (
        <Fade direction={"bottomToTop"} duration={1}>
          <FormSubmissionMessage message={formSubmissionStatus} />
        </Fade>
      ) : (
        <Fade direction={"bottomToTop"} duration={1}>
          <form onSubmit={handleSubmit} ref={form} className="p-8 xs:p-1.5">
            <div className="uppercase xs:text-3xl xs:p-2.5 sm:text-4xl md:text-4xl lg:text-4xl xl:text-4xl tracking-tighter font-medium w-full ">
              Contact support
            </div>
            <div className="xs:text-xs xs:p-1.5 sm:text-sm md:text-sm lg:text-sm xl:text-sm text-base tracking-wide font-normal w-full my-6">
              Complete the form below to get in touch with the Algomint team
            </div>
            <div className="flex xs:flex-col sm:flex-col md:flex-col lg:flex-row lg:space-x-6 xl:flex-row xl:space-x-6 2xl:flex-row 2xl:space-x-6 w-full ">
              <div className="xs:w-full sm:w-full md:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2">
                <Input
                  label={"First Name"}
                  value={firstName}
                  isTextArea={false}
                  type={"text"}
                  handleInputChange={(value) => {
                    setFirstName(value);
                  }}
                />
              </div>
              <div className="xs:w-full sm:w-full md:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2">
                <Input
                  label={"Last Name"}
                  value={lastName}
                  type={"text"}
                  isTextArea={false}
                  handleInputChange={(value) => {
                    setLastName(value);
                  }}
                />
              </div>
            </div>
            <div className="flex xs:flex-col sm:flex-col md:flex-col lg:flex-row lg:space-x-6 xl:flex-row xl:space-x-6 2xl:flex-row 2xl:space-x-6 w-full">
              <div className="xs:w-full sm:w-full md:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2">
                <Input
                  label={"Email"}
                  value={email}
                  type={"email"}
                  isTextArea={false}
                  handleInputChange={(value) => {
                    setEmail(value);
                  }}
                />
              </div>
              <div className="xs:w-full sm:w-full md:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2">
                <ImageUploader setfileUploadToken={setfileUploadToken} />
              </div>
            </div>
            <div className="w-full my-4">
              <Input
                label={"message"}
                value={message}
                isTextArea={true}
                handleInputChange={(value) => {
                  setMessage(value);
                }}
              />
            </div>
            <ReCaptchaV2
              sitekey={process.env.GATSBY_RECAPTCHA_KEY}
              onChange={handleToken}
              onExpired={handleExpire}
              onErrored={handleError}
            />
            <div className="w-full flex justify-center">
              <button type="submit" className="submit-btn cursor-pointer">
                Submit
              </button>
            </div>
          </form>
        </Fade>
      )}
    </div>
  );
}

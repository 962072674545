import React, { useState } from "react";
import "./input.css";
export default function input({ handleInputChange, label, isTextArea, type }) {
  const handleChange = (event) => {
    event.preventDefault();
    const { value } = event.target;
    handleInputChange(value);
  };
  return (
    <div className="mb-6">
      <label
        className="block text-gray-700 text-sm font-normal ml-2 mb-2 uppercase"
        htmlFor="inputField"
      >
        {label}*
      </label>
      {isTextArea ? (
        <textarea
          id="inputField"
          required
          onChange={handleChange}
          className="input-textarea  bg-transparent rounded-lg w-full border p-4"
          placeholder={`Your ${label.toLowerCase()}..`}
        />
      ) : type == "email" ? (
        <input
          className="input bg-transparent rounded-full w-full border h-8 p-3 "
          id="inputField"
          required
          type="email"
          onChange={handleChange}
          placeholder={`Type ${label.toLowerCase()}..`}
        />
      ) : (
        <input
          className="input bg-transparent rounded-full w-full border h-8 p-3 "
          id="inputField"
          required
          type="text"
          onChange={handleChange}
          placeholder={`Type ${label.toLowerCase()}..`}
        />
      )}
    </div>
  );
}

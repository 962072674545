import React, { useState } from "react";
import HyperLinkButton from "../HyperlinkButton";
import ButtonTransparent from "../ButtonTransparent";
import "./AnnouncementBar.css";
export default function AnnouncementBar({ title, content }) {
  const [closeBanner, setCloseBanner] = useState(false);
  return (
    <div
      className={`w-96 h-auto xs:w-auto xs:ml-3 AnnouncementBar-wrapper right-8 top-32 xs:top-20 sm:top-32 rounded-lg ${
        closeBanner ? "hidden" : ""
      }`}
    >
      <img
        src={"/icons/close.svg"}
        className="absolute top-0 right-0 cursor-pointer closeBtn h-4 w-4 mr-2 mt-2"
        onClick={() => {
          setCloseBanner(true);
        }}
      />
      <br />
      <div className="AnnouncementBar-content mx-4 space-y-4">
        <h2 className="announcementBar-title font-black ">
          Algomint Token now live
        </h2>
        <div className="announcementBar-content xs:text-xs sm:text-sm md:text-md lg:text-md xl:text-md 2xl:text-md">
          Governance Token Public Listing on 30 Mar, 2022 see link below for the
          full story.
        </div>
        <HyperLinkButton
          title={"READ MORE"}
          url={"/token-launch"}
          navigateInApp={true}
        />
        <hr size="10" />
        <h2 className="announcementBar-title font-black ">
          Stay liquid during governance
        </h2>
        <div className="announcementBar-content xs:text-xs sm:text-sm md:text-md lg:text-md xl:text-md 2xl:text-md">
          Click{" "}
          <a
            href="https://app.folks.finance/algo-liquid-governance?ref=algomint"
            target="blank"
            className="text-accentColor"
          >
            here
          </a>{" "}
          to Participate
        </div>
        <hr size="10" />
        <h2 className="announcementBar-title font-black">Available on:</h2>
        <div className="grid gap-2 grid-cols-2 2xl:gap-3 xs:w-full xs:h-full">
          <ButtonTransparent
            title={"Humble Swap"}
            url={"https://app.humble.sh/swap"}
            displayIcon={false}
            showBorder={true}
            textColor={"black"}
            borderColor={"black"}
            borderColoronHover={"black"}
          />
          <ButtonTransparent
            title={"Folks Finance"}
            url={"https://app.folks.finance"}
            displayIcon={false}
            showBorder={true}
            borderColor={"black"}
            textColor={"black"}
            borderColoronHover={"black"}
          />
          <ButtonTransparent
            title={"Pact"}
            url={"https://app.pact.fi/swap"}
            displayIcon={false}
            borderColor={"black"}
            showBorder={true}
            textColor={"black"}
            borderColoronHover={"black"}
          />{" "}
          <ButtonTransparent
            title={"Tinyman"}
            url={"https://app.tinyman.org/"}
            displayIcon={false}
            borderColor={"black"}
            showBorder={true}
            textColor={"black"}
            borderColoronHover={"black"}
          />
          <ButtonTransparent
            title={"xBacked"}
            url={"https://www.xbacked.io/"}
            displayIcon={false}
            borderColor={"black"}
            showBorder={true}
            textColor={"black"}
            borderColoronHover={"black"}
          />
          <ButtonTransparent
            title={"Algorai"}
            url={"https://algorai.finance/"}
            displayIcon={false}
            borderColor={"black"}
            showBorder={true}
            textColor={"black"}
            borderColoronHover={"black"}
          />
        </div>
        <hr size="10" />
        <h2 className="announcementBar-title font-black">Asset ID's:</h2>
        <div className="assetIds grid gap-2 grid-cols-2 2xl:gap-3 xs:w-full xs:h-full mb-6">
          <ButtonTransparent
            title={"goBTC"}
            url={"https://allo.info/asset/386192725"}
            displayIcon={false}
            showBorder={true}
            borderColor={"black"}
            textColor={"black"}
          />
          <ButtonTransparent
            title={"goETH"}
            url={"https://allo.info/asset/386195940"}
            displayIcon={false}
            showBorder={true}
            borderColor={"black"}
            textColor={"black"}
          />
          <ButtonTransparent
            title={"goMINT"}
            url={"https://allo.info/asset/441139422"}
            displayIcon={false}
            showBorder={true}
            borderColor={"black"}
            textColor={"black"}
          />
          <ButtonTransparent
            title={"goUSD"}
            url={"https://allo.info/asset/672913181"}
            displayIcon={false}
            showBorder={true}
            borderColor={"black"}
            textColor={"black"}
          />
        </div>
        <br />
      </div>
    </div>
  );
}

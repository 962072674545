import React, { useState, useEffect } from "react";
import axios from "axios";
import TVLAssetsV2 from "../TvlAssetsV2";
import Fade from "../../animations/Fade";
import TotalLPData from "../TotalLPTokens";
import TVLGroupedData from "../TVLGroupedData";
export default function Tvl() {
  const [tvlData, setTvlData] = useState({});

  useEffect(() => {
    getTVLData();
  }, []);

  async function getTVLData() {
    try {
      const response = await axios.get("https://tvl-api.algomint.io/tvl-data");
      if (response.status === 200) {
        await setTvlData((prev) => ({
          ...prev,
          summary: response.data.data.summary,
          algomintAssets: response.data.data.algomintAssets,
          baskets: response.data.data.baskets,
        }));
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className="flex flex-col xs:space-y-24 sm:space-y-24">
      {Object.keys(tvlData).length !== 0 ? (
        <Fade direction={"bottomToTop"} duration={1}>
          <TVLAssetsV2
            summary={tvlData.summary}
            firstTitle={"Total Circulating"}
            secondTitle={"Collateral"}
            titleBgColor={"pink"}
            displayChevron={true}
            expandedContent={
              <TVLGroupedData
                algomintAssets={tvlData.algomintAssets}
                baskets={tvlData.baskets}
              />
            }
          />
        </Fade>
      ) : null}
    </div>
  );
}

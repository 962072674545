import React, { useState } from "react";
import ReactPlayer from "react-player";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import "./VideoPlayer.css";

const Video = ({ isVideoPlaying, stopVideo }) => {
  const closeIcon = (
    <svg width="25" height="25" viewBox="0 0 25 25">
      <g id="X">
        <path
          fill="white"
          fillRule="evenodd"
          d="M11.995,10.556 L10.556,11.995 L6.000,7.439 L1.444,11.995 L0.005,10.556 L4.561,6.000 L0.005,1.444 L1.444,0.005 L6.000,4.561 L10.556,0.005 L11.995,1.444 L7.439,6.000 L11.995,10.556 Z"
        />
      </g>
    </svg>
  );
  return (
    <Modal
      open={isVideoPlaying}
      onClose={() => {
        stopVideo();
      }}
      center
      classNames={{
        overlay: "customOverlay",
        modal: "customModal",
      }}
      closeIcon={closeIcon}
    >
      <div className="react-player-wrapper">
        <ReactPlayer
          style={{ pointerEvents: "none" }}
          url="https://vimeo.com/579707824"
          playing={true}
          stopOnUnmount={false}
          controls={true}
          width={"100%"}
          height={"100%"}
          config={{
            vimeo: {
              playerOptions: {
                playsinline: true,
              },
            },
          }}
        />
      </div>
    </Modal>
  );
};

export default Video;

import React from "react";
import Card from "../Card";
import { members, founders } from "../data/teamMembers";
import Fade from "../../animations/Fade";
import "./Team.css";

export default function team() {
  return (
    <>
      <Fade direction={"bottomToTop"} duration={1}>
        <div className="team-quote flex my-72 xs:text-3xl sm:text-4xl md:text-4xl lg:text-5xl xl:text-5xl font-medium uppercase ">
          We believe in change, a blockchain future and a global open economy.
        </div>
      </Fade>

      <div className="flex justify-between pb-6">
        <h1 className="text-4xl font-medium">
          <Fade direction={"bottomToTop"} duration={1}>
            Team
          </Fade>
        </h1>
      </div>
      <hr />
      <br />
      <div className="flex flex-wrap w-full  xs:flex-col sm:justify-center lg:flex-row xl:flex-row 2xl:flex-row">
        {members.map(({ name, info, linkedIn, twitter, img, role }) => {
          return (
            <Card
              title={name}
              role={role}
              description={info}
              linkedIn={linkedIn}
              twitter={twitter}
              img={img}
              key={name}
            />
          );
        })}
      </div>
      <br />
      <div className="flex justify-between pb-6">
        <h1 className="text-4xl font-medium">
          <Fade direction={"bottomToTop"} duration={1}>
            Founders
          </Fade>
        </h1>
      </div>
      <hr />
      <br />
      <div className="flex flex-wrap w-full sm:justify-center  xs:flex-col lg:flex-row xl:flex-row 2xl:flex-row">
        {founders.map(({ name, info, linkedIn, twitter, img, role }) => {
          return (
            <Card
              title={name}
              role={role}
              description={info}
              linkedIn={linkedIn}
              twitter={twitter}
              img={img}
              key={name}
            />
          );
        })}
      </div>
    </>
  );
}
